<template>
    <div v-if="isReady">
        <destination-header-component :content="headerContent"></destination-header-component>

        <!-- Featured Destinations -->
        <section v-if="featuredDestinations.content.length" class="container px-lg-0 pb-0 my-4">
            <h2 class="text--serif mb-2">{{ featuredDestinations.header }}</h2>
            <feature-cards-component class="featured-destinations" :content="featuredDestinations.content" :use-paging="true" @feature-card-click="trackFeatureCardDestination"></feature-cards-component>
        </section>

        <!-- Regions -->
        <section v-if="regions.content.length" class="container px-lg-0 pb-0 my-4">
            <h2 class="text--serif mb-2">{{ regions.header }}</h2>
            <feature-cards-component class="destinations-regions" :content="regions.content" @feature-card-click="trackFeatureCardDestination"></feature-cards-component>
        </section>

        <find-an-advisor-cta-component></find-an-advisor-cta-component>

        <div class="ad-zone"><broadstreet-zone zone-id="78463"></broadstreet-zone></div>

        <!-- Themed Articles -->
        <section v-if="themedArticles.content.length" class="container px-lg-0 pb-0 my-4">
            <h2 class="text--serif mb-2" v-html="themedArticles.header"></h2>
            <content-tiles-component :content-tiles="themedArticles.content" class="themed-articles" :layout-class="getColumnClass(themedArticles.content.length) + ' -tall -featured'" :should-track-content-tile-views="true"></content-tiles-component>
        </section>

        <!-- Featured Articles -->
        <section v-if="featuredArticles.content.length" class="container px-lg-0 pb-0 my-4">
            <h2 class="text--serif mb-2" v-html="featuredArticles.header"></h2>
            <content-tiles-component :content-tiles="featuredArticles.content" class="featured-articles" :layout-class="getColumnClass(featuredArticles.content.length) + ' -tall -flush'" :should-track-content-tile-views="true"></content-tiles-component>
        </section>

        <!-- Products -->
        <product-cards-component :content="hotels" class="product-cards-container"></product-cards-component>
        <product-cards-component :content="cruises" :class="['product-cards-container', { 'pt-0': hotels.productCards.length }]"></product-cards-component>
        <product-cards-component :content="tours" :class="['product-cards-container', { 'pt-0': (hotels.productCards.length || cruises.productCards.length ) }]"></product-cards-component>
    </div>
    <LogoSplash v-else />
</template>


<script setup lang="ts">
    import FindAnAdvisorCtaComponent from "components/advisor/find-an-advisor-cta.vue";
    import DestinationHeaderComponent from "components/destination/destination-header.vue";
    import ProductCardsComponent from "components/products/product-cards.vue";
    import ContentTilesComponent from "components/shared/content-tiles.vue";
    import FeatureCardsComponent from "components/shared/feature-cards.vue";
    import LogoSplash from "components/shared/logo-splash.vue";
    import { DotCMSBasicArticleResponse, DotCMSDestinationsLandingPageResponse, DotCMSDestinationsResponse } from "interfaces/responses/dotcms-responses";
    import { ContentSection, ContentTile, FeatureCard, ProductCards, ProductCardSearchResult } from "interfaces/card";
    import { ProductType } from "interfaces/enums";
    import { PageHeader } from "interfaces/render";
    import { getCmsContent } from "services/api/cms";
    import { extractCategories } from "services/helpers/categories";
    import { buildDestinationPageURL } from "services/helpers/destinations";
    import { generateCmsImageUrl } from "services/helpers/images";
    import { trackEvent } from "services/analytics";
    import { getSponsoredAndRandomProducts, getViewAllLabel } from "services/product-cards";
    import { cobrandLink } from "virtuoso-shared-web-ui";
    import { ref } from "vue";

    const isReady = ref(false);
    const headerContent = ref<PageHeader>({
        title: "",
        subhead: "",
        backgroundImageUrl: "",
        backgroundImageCropFocus: "",
        backgroundImageDesktopUrl: "",
        backgroundImageDesktopCropFocus: "",
        metadata: {
            description: "",
            keywords: "",
            title: "Destinations"
        }
    });

    const featuredDestinations = ref<ContentSection<FeatureCard[]>>({
        header: "Popular Destinations",
        content: []
    });

    const regions = ref<ContentSection<FeatureCard[]>>({
        header: "Browse Destinations",
        content: []
    });

    const themedArticles = ref<ContentSection<ContentTile[]>>({
        header: "Fun Destinations",
        content: []
    });
    const featuredArticles = ref<ContentSection<ContentTile[]>>({
        header: "Get Inspired",
        content: []
    });

    const cruises = ref<ProductCards>({
        header: "Cruises",
        productCards: [],
        productType: ProductType.CRUISES,
        viewAllLink: cobrandLink("/travel/luxury-cruises/search"),
        viewAllText: "See More Cruises"
    });

    const hotels = ref<ProductCards>({
        header: "Hotels",
        productCards: [],
        productType: ProductType.HOTELS,
        viewAllLink: cobrandLink("/travel/luxury-hotels/search"),
        viewAllText: "See More Hotels"
    });

    const tours = ref<ProductCards>({
        header: "Tours & Experiences",
        productCards: [],
        productType: ProductType.TOURS,
        viewAllLink: cobrandLink("/travel/luxury-tours/search"),
        viewAllText: "See More Tours & Experiences"
    });

    const trackFeatureCardDestination = (featuredCard: FeatureCard): void => {
            trackEvent("select_item", {
                item_id: featuredCard.url,
                item_name: featuredCard.name,
                item_category: "Destination"
            });
    };

    getCmsContent<DotCMSDestinationsLandingPageResponse[]>({
        contentTypes: ["DestinationsLandingPage"],
        depth: 1,
        limit: 1
    }).then((destinationJSON) => {

        if (destinationJSON?.length) {

            // Transform all the returned data into simple shapes
            const dest = destinationJSON[0];

            // Get products, sponsored + random
            getSponsoredAndRandomProducts(ProductType.CRUISES, { rowsLimit: 3 }, dest.sponsoredCruiseIds).then((prods: ProductCardSearchResult) => {
                cruises.value.productCards = prods.productCards;
                cruises.value.viewAllText = getViewAllLabel(ProductType.CRUISES, prods.totalResults, 3);
            });
            getSponsoredAndRandomProducts(ProductType.HOTELS, { rowsLimit: 3 }, dest.sponsoredHotelIds).then((prods: ProductCardSearchResult) => {
                hotels.value.productCards = prods.productCards;
                hotels.value.viewAllText = getViewAllLabel(ProductType.HOTELS, prods.totalResults, 3);
            });

            getSponsoredAndRandomProducts(ProductType.TOURS, { rowsLimit: 3 }, dest.sponsoredTourIds).then((prods: ProductCardSearchResult) => {
                tours.value.productCards = prods.productCards;
                cruises.value.viewAllText = getViewAllLabel(ProductType.TOURS, prods.totalResults, 3);
            });

            // Destination header
            headerContent.value = {
                title: dest.title,
                subhead: (dest.tagline && dest.tagline !== "") ? dest.tagline : "",
                backgroundImageUrl: (dest.headerImage && dest.headerImage !== "") ? generateCmsImageUrl(dest.headerImage) : "",
                backgroundImageCropFocus: (dest.headerImageCropFocus && dest.headerImageCropFocus !== "") ? dest.headerImageCropFocus : "",
                backgroundImageDesktopUrl: (dest.headerImageDesktop && dest.headerImageDesktop !== "") ? generateCmsImageUrl(dest.headerImageDesktop) : "",
                backgroundImageDesktopCropFocus: (dest.headerImageDesktopCropFocus && dest.headerImageDesktopCropFocus !== "") ? dest.headerImageDesktopCropFocus : "",
                metadata: {
                    description: (dest.metaDescription && dest.metaDescription !== "") ? dest.metaDescription : "",
                    keywords: (dest.metaKeywords && dest.metaKeywords !== "") ? dest.metaKeywords : "",
                    title: (dest.metaHeadline && dest.metaHeadline !== "") ? dest.metaHeadline : "Destinations"
                }
            };

            // Featured Destinations
            featuredDestinations.value.header = dest.featuredDestinationsHeader;
            (dest.featuredDestinations).forEach((featuredDest: DotCMSDestinationsResponse) => {
                featuredDestinations.value.content.push({
                    imageUrl: generateCmsImageUrl(featuredDest.gridImage),
                    url: buildDestinationPageURL(featuredDest.destinationTag, featuredDest.isArbitraryDestination),
                    name: featuredDest.title
                });
            });

            // // Region Destinations
            regions.value.header = dest.regionsHeader;
            (dest.regions).forEach((region: DotCMSDestinationsResponse) => {
                regions.value.content.push({
                    imageUrl: generateCmsImageUrl(region.gridImage),
                    url: buildDestinationPageURL(region.destinationTag, region.isArbitraryDestination),
                    name: region.title
                });
            });

            // // Themed Articles
            themedArticles.value.header = dest.themedArticlesHeader;
            (dest.themedArticles).forEach((article: DotCMSBasicArticleResponse) => {
                themedArticles.value.content.push({
                    categories: (article.categories) ? extractCategories(article.categories) : [],
                    identifier: article.identifier,
                    imageCropFocus: article.previewImageCropFocus,
                    imageUrl: generateCmsImageUrl(article.previewImage, { width: 596 }),
                    title: article.headline,
                    url: cobrandLink(`/travel/articles/${article.articleUrl}`)
                });
            });

            // // Featured Articles
            featuredArticles.value.header = dest.featuredArticlesHeader;
            (dest.featuredArticles).forEach((article: DotCMSBasicArticleResponse) => {
                featuredArticles.value.content.push({
                    categories: [],
                    identifier: article.identifier,
                    imageCropFocus: article.previewImageCropFocus,
                    imageUrl: generateCmsImageUrl(article.previewImage, { width: 596 }),
                    title: article.headline,
                    url: cobrandLink(`/travel/articles/${article.articleUrl}`)
                });
            });
            isReady.value = true;
        }
    });

    const getColumnClass = (numItems: number): string => {
        return (numItems % 3 === 0) ? "-threecols" : "-twocols";
    };
</script>
