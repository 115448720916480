import DestinationsLandingPageComponent from "components/pages/destinations-landing-page.vue";
import DestinationsComponent from "components/pages/destinations.vue";
import { App, createApp } from "vue";
import { mountApp } from "vue-app";

let app: App;
if (window.VIRTUOSO.destinationKey) {
    app = createApp(DestinationsComponent, { destinationKey: window.VIRTUOSO.destinationKey });
} else {
    app = createApp(DestinationsLandingPageComponent);
}

mountApp(app, "page-app");